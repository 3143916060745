<script>
import ComitenteSelect from "components/comitente/components/include/ComitenteSelect.vue"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import {list, newHabilitacao, updateHabilitacao, deleteHabilitacao} from '@/domain/arrematante/services/habilitacao-comitente'
import SlCheckbox from "@/reuse/input/Checkbox.vue";

const mockInput = {
  comitente: null,
  tipo: null,
  descricao: null,
  active: true
}
export default {
  name: "ArrematanteHabilitacao",
  props: {
    arrematante: {required: true}
  },
  data () {
    return {
      input: JSON.parse(JSON.stringify(mockInput)),
      loading: true,
      habilitacaoComitente: []
    }
  },
  computed: {
    tiposHabilitacao () {
      return [
        {label: 'Pode Comprar e ver eventos privados', value: 1},
        {label: 'Restrição Compra', value: 2},
      ]
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      list(10000, 1, '&arrematante=' + this.arrematante.id)
          .then(response => {
            this.habilitacaoComitente = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    adicionarHabilitacao () {
      const data = JSON.parse(JSON.stringify(this.input))
      data.arrematante = this.arrematante.id
      data.comitente = data.comitente?.id ?? data.comitente
      newHabilitacao(data)
          .then(response => {
            this.habilitacaoComitente.push(response.data)
            this.input = JSON.parse(JSON.stringify(mockInput))
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    removerHabilitacao (h) {
      this.$uloc.dialog({
        title: 'Confimar remoção deste registro?',
        message: '',
        cancel: true,
        color: 'negative'
      })
          .then(() => {
            this.$uloc.notify({color: 'warning', message: `Excluindo, aguarde...`})
            deleteHabilitacao(h.id)
                .then(response => {
                  this.load()
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {})
    },
    updateActive (h) {
      this.$nextTick(() => {
        setTimeout(() => {
          updateHabilitacao(h.id, {
            active: h.active
          }).then(response => {
            console.log(response.data)
          }).catch(error => {
            this.alertApiError(error)
          })
        }, 1000)
      })
    }
  },
  components: {
    SlCheckbox,
    ErpSelect,
    ComitenteSelect
  }
}
</script>

<template>
<div class="wrapper">
  <div class="font-12 font-bold m-b">Lista de Habilitação e Restrição em Comitentes</div>
  <div class="e-input-modern label-size-1 size1">
    <div style="width: 100%; display: flex">
      <div class="m-r-xs" style="min-width: 50%;">
        <comitente-select class="col-grow" v-model="input.comitente" />
      </div>
      <erp-select placeholder="Tipo de Habilitação" v-model="input.tipo" :options="tiposHabilitacao" />
      <e-btn @click="adicionarHabilitacao" no-caps class="m-l-xs" style="width: 220px">
        <i class="fa fa-plus m-r-sm"></i> Adicionar
      </e-btn>
    </div>
  </div>
  <div class="a-restricao-list">
    <table>
      <thead>
      <tr>
        <th>Comitente</th>
        <th>Tipo</th>
        <th>Descrição</th>
        <th>Registro</th>
        <th>Ativo</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="h in habilitacaoComitente" :key="h.id">
        <td>{{ h.comitente.pessoa.name }}</td>
        <td>
          <div v-if="h.tipo === 1" class="text-positive">Pode Comprar</div>
          <div v-if="h.tipo === 2" class="text-negative">Restrição</div>
        </td>
        <td>{{h.descricao}}</td>
        <td>{{h.createdAt|formatDate}}</td>
        <td>
          <sl-checkbox @click.native="updateActive(h)" v-model="h.active" yes-val="" no-val="" />
<!--          <div v-if="h.active" class="text-positive">Ativo</div><div class="text-negative" v-else>Inativo</div>-->
        </td>
        <td>
          <u-btn @click="removerHabilitacao(h)" rounded round flat dense size="md"><i class="fa fa-times"></i></u-btn>
        </td>
      </tr>
      <tr v-if="!habilitacaoComitente || !habilitacaoComitente.length">
        <td colspan="100%">Nenhum registro para este cliente</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<style lang="stylus">
.a-restricao-list {
  margin 30px -5px 0
  table {
    width 100%
    th {
      text-align left
    }
    td, th {
      padding 5px
      font-size 12px
    }
  }
}
</style>

import http from '../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = '/api/arrematante/habilitacaoComitente?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/arrematante/habilitacaoComitente/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newHabilitacao = (data) => {
  let url = `/api/arrematante/habilitacaoComitente`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateHabilitacao = (id, data) => {
  let url = `/api/arrematante/habilitacaoComitente/${id}`
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteHabilitacao = (id) => {
  let url = `/api/arrematante/habilitacaoComitente/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
